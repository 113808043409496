<template>
  <div>
    <Card>
      <template #header>
        <div class="baslik w-100">
          <div>Özellik Kategorileri</div>
          <div>
            <router-link to="feature/details">
              <button class="iconPlus">
                <p style="font-size: 13px" class="btn btn-secondary p-0">
                  <i class="flaticon2-plus mb-1"></i> Yeni Kullanım Niteliği
                  Ekle
                </p>
              </button>
            </router-link>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-9"></div>
        <div class="col-3">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <hr class="w-100" />
      </div>

      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.email="{ item }">
          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </template>
        <template v-slot:item.isActive="{ item }">
          <div v-html="$customFunctions.getIsActiveBadge(item.isActive)"></div>
        </template>
        <template v-slot:item.action="{ item }" class="p-0">
          <button @click="addFileModal = true">
            <i class="flaticon-eye icon-lg"></i>
          </button>
          <button class="border-0" @click="addFileModal2 = true">
            <i class="flaticon2-trash text-dark text-md ml-4"></i>
          </button>
        </template>
      </List>
      <!-- Create and Actions Popups -->
      <InsertUpdateModal
        :title="
          selectedItem == ''
            ? 'Sistem Kullanıcı Kaydı Ekle'
            : 'Sistem Kullanıcını Düzenle'
        "
        :show="showInsertUpdate"
        @closeDetail="closeInsertUpdate()"
        @confirm="confirmModal()"
        :resetForm="resetForm"
      >
        <InsertUpdateOperations
          :selectedItem="selectedItem"
          :confirmModal="confirmCreate"
          @resetInsertUpdateForm="resetInsertUpdateForm"
        />
      </InsertUpdateModal>

      <DetailModal
        title="Fiili Kullanım Nitelikleri"
        :show="showDetail"
        @openInsertUpdate="openInsertUpdate(selectedItem)"
        @closeDetail="closeDetail"
      >
        <DetailView :selectedItem="selectedItem" />
      </DetailModal>

      <ImportModal
        :show="showImportModal"
        importApiEndpoint="user/import"
        @closeDetail="closeImportModal()"
        @updateList="getList"
        @downloadSampleFile="exportOperations(true)"
      />

      <DeleteOperation
        :url="deleteOperationUrl"
        :params="deleteIds"
        @updateList="getList"
      />
      <b-modal v-model="addFileModal" id="note-modal">
        <template #modal-header>
          <h4>Kategori Bilgileri</h4>
        </template>
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <label for="">Kategori Adı</label>
              <input type="text" class="form-control" value="Alt Yapı" />
            </div>
          </div>
        </div>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="addFileModal = false">
              Vazgeç
            </b-button>
            <b-button variant="success" class="ml-4"> Kaydet </b-button>
          </div>
        </template>
      </b-modal>
      <b-modal v-model="addFileModal2" id="note-modal">
        <template #modal-header>
          <h4>İşlem Onayı</h4>
        </template>
        <p>
          Kategori ve bu kategoriye ait tüm özellikler silinecektir. Onaylıyor
          musunuz?
        </p>
        <template #modal-footer>
          <div class="d-flex justify-content-end w-100">
            <b-button variant="light" @click="addFileModal2 = false">
              Vazgeç
            </b-button>
            <b-button variant="danger" @click="addNewNote" class="ml-4">
              Evet
            </b-button>
          </div>
        </template>
      </b-modal>
    </Card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "systemUsers",
  data() {
    return {
      addNewNote: "",
      addFileModal: false,
      addFileModal2: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "İsim", value: "fullName" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Özellik Kategorileri", route: "/manage/feature" },
    ]);
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      this.items = [
        {
          id: "1",
          fullName: "Akaryakıt Tesisi",
        },
        {
          id: "2",
          fullName: "Arazi",
        },
        {
          id: "3",
          fullName: "Arsa",
        },
        {
          id: "4",
          fullName: "Avm",
        },
        {
          id: "5",
          fullName: "Büro",
        },
        {
          id: "6",
          fullName: "Depo",
        },
        {
          id: "7",
          fullName: "Dükkan",
        },
        {
          id: "8",
          fullName: "Eğitim Tesisi",
        },
        {
          id: "9",
          fullName: "Eğlence Tesisi",
        },
        {
          id: "10",
          fullName: "Hali Arazi",
        },
      ];
      this.totalItems = 3;

      this.pagination = {
        current_page: 1,
        total_pages: 1,
        total_items: 3,
      };
      this.loading = false;

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    confirmModal() {
      this.confirmCreate = true;
      setTimeout(() => {
        this.confirmCreate = false;
      }, 200);
    },
    resetInsertUpdateForm(closeInsertUpdate = false) {
      if (closeInsertUpdate) {
        this.closeInsertUpdate();
        this.getList();
      }

      this.resetForm = true;
      setTimeout(() => {
        this.resetForm = false;
      }, 200);
    },
    closeInsertUpdate() {
      setTimeout(() => {
        this.selectedItem = "";
      }, 100);
      this.showInsertUpdate = false;
    },
    openInsertUpdate(item) {
      this.selectedItem = item;
      this.showInsertUpdate = true;
    },
    closeDetail() {
      this.showDetail = false;
    },
    openDetail(item) {
      this.selectedItem = item;
      this.showDetail = true;
    },
    closeImportModal() {
      this.showImportModal = false;
    },
    openImportModal() {
      this.showImportModal = true;
    },
    openDeleteHandler(item) {
      this.deleteOperationUrl = `user`;
      this.deleteIds = item.id;
      this.$bvModal.show("modal-deleteOperation");
    },
    selectedItemIdsChanged(payload) {
      this.listSelectedItemIds = payload;
    },
    actions(payload) {
      if (
        this.listSelectedItemIds === [] ||
        this.listSelectedItemIds.length == 0
      ) {
        this.$generateNotification(
          this,
          "warning",
          "İşlem yapılacak seçili kayıt bulunamadı!",
          "İşleme devam edilemiyor."
        );
      } else {
        if (payload == "allRemove") {
          this.deleteOperationUrl = `user`;
          this.deleteIds = this.listSelectedItemIds;
          this.$bvModal.show("modal-deleteOperation");
        } else if (payload == "allInActive" || payload == "allActive") {
          this.loading = true;
          this.$dbFunctions
            .update(`user/update-status`, {
              ids: this.listSelectedItemIds,
              isActive: payload == "allActive",
            })
            .then((res) => {
              this.$dbFunctions.setResponse(this, res);
              this.getList();
            })
            .catch((err) => {
              this.$dbFunctions.setResponse(this, err);
            })
            .finally(() => {
              this.loading = false;
            });
        } else if (payload == "allExport") {
          this.exportOperations(false);
        }
      }
    },
    exportOperations(onlyHeaders) {
      this.loading = true;
      let payload = {
        Ids: this.listSelectedItemIds,
        onlyHeaders: onlyHeaders,
      };

      this.$dbFunctions
        .insertOrUpdate(`user/export`, "export", payload)
        .then((res) => {
          // this.$customFunctions.downloadFile(res.data);

          this.$generateNotification(
            this,
            "success",
            `Size özel dosyanız oluşturuldu. <a href="${res.data}" target="_blank" class="text-dark-50 text-hover-dark">Burayı tıklayarak</a> dosyanızı indirebilirsiniz.`,
            "Dosyanız hazır!"
          );
        })
        .catch((err) => {
          this.$dbFunctions.setResponse(this, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  components: {
    InsertUpdateOperations: () =>
      import("@/components/systemUser/modals/InsertUpdateOperations"),
    DetailView: () => import("@/components/systemUser/modals/DetailView"),
  },
};
</script>
